<template>
  <div>
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />

    <div class="upload">
      <el-upload
        class="upload-demo"
        drag
        action="https://jsonplaceholder.typicode.com/posts/"
        :auto-upload="false"
        :show-file-list="false"
        :on-change="changePictureUploadIDFront"
      >
        <img v-if="imageUrlFront" :src="imageUrlFront" class="avatar" />
        <i v-else class="el-icon-upload"></i>
        <div class="el-upload__text">点击或拖拽上传身份证<em>人像面</em></div>
      </el-upload>

      <el-upload
        class="upload-demo"
        drag
        action="https://jsonplaceholder.typicode.com/posts/"
        multiple
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">点击或拖拽上传身份证<em>国徽面</em></div>
      </el-upload>

      <el-upload
        class="upload-demo"
        drag
        action="https://jsonplaceholder.typicode.com/posts/"
        multiple
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">点击或拖拽上传<em>手持证件照</em></div>
      </el-upload>
    </div>

    <div class="real_name_input">
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="手机号" prop="phone">
          <el-input
            v-model="ruleForm.phone"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="真实姓名" prop="name">
          <el-input
            v-model="ruleForm.name"
            placeholder="请输入真实姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="身份证号" prop="num">
          <el-input
            v-model="ruleForm.num"
            placeholder="请输入身份证号"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button round type="success" @click="submitForm('ruleForm')"
            >提 交</el-button
          >
          <el-button round type="info" @click="resetForm('ruleForm')"
            >重 置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";
export default {
  components: {
    Breadcrumb,
    Pagination,
  },
  data() {
    return {
      ruleForm: {
        phone: "",
        name: "",
        num: "",
      },
      rules: {
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        name: [{ required: true, message: "请输入真实姓名", trigger: "blur" }],
        num: [{ required: true, message: "请输入身份证号", trigger: "blur" }],
      },
      BreadcrumbCon: [
        {
          name: "日常管理",
        },
        {
          name: "实名认证",
        },
        {
          name: "人工实名认证",
        },
      ], // 面包屑数据

      imageUrlFront: "",

      // 分页数据
      pageLength: 20,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageIndex: 1, //要传过去的数据 第几页
    };
  },

  methods: {
    // 文件发生变化时
    changePictureUploadIDFront(file, fileList) {
      console.log(`------`, file);
      const isJPG = file.raw.type === "image/jpeg";
      const isPNG = file.raw.type === "image/png";
      const isLt2M = file.raw.size / 1024 / 1024 < 2;

      if (file.raw) {
        if (isJPG || isPNG) {
          const fileFormData = new FormData();
          fileFormData.append("files", file.raw);
          // addIdCard(fileFormData).then((res) => {
          //   //调用上传接口
          //   if (res) {
          //     this.imageUrlFront = URL.createObjectURL(file.raw); //接口调用成功后赋值src
          //     this.$message({
          //       message: "恭喜你，上传成功！",
          //       type: "success",
          //     });
          //   }
          // });
          this.imageUrlFront = URL.createObjectURL(file.raw);
        } else {
          this.$message.error("上传图片只能是 JPG或PNG 格式!");
        }
      }

      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      // 返回图是jpg或png格式并且大小不超过2MB
      return (isJPG || isPNG) && isLt2M;
    },

    handlePageChange(data) {
      this.pageIndex = data.pageIndex;
      this.pageSize = data.pageSize;
      // this.loadData(); //这是重新获取数据的方法
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
.upload {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
  }
}

.real_name_input {
  width: 60%;
  margin: 0 auto;

  ::v-deep .el-form-item__content {
    display: flex;
    justify-content: flex-end;
  }

  .el-button {
    width: 100px;
  }
}
</style>
